<template>
	<div v-if="isSuperAdmin">
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="columnDefs"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
	<div v-else>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="columnDefs"
			:showDeleteBtn="true"
			:actions="actions"
			:externalFilter="externalFilter"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import RulesActions from './PmRulesActions';
// eslint-disable-next-line no-unused-vars
import { deleteRule } from '../../api/databases_API';
import { isSuperAdmin, setRules, setTimeunits, search } from '../../api/common';

//import { deleteRule } from '../../api/databases_API';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmrulesgrid',
	data() {
		return {
			modelName: 'pmrules',
			actions: RulesActions.actions,
			masterDetailColumnVisibles: {
				name: true,
				acronym: true,
				pmdatasourcetypename: true,
				severityname: true,
				condition: true,
				windowrule: true,
				windowtimeunitname: true,
				frequency: true,
				frequencytimeunitname: true,
				applytoall: true,
				isaverage: true,
				organizationname: true,
				disabled: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				applytoall: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				isaverage: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				severityname: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = `<label style="font-weight:bold;color:${rowData.color}">${cellData}</label>`);
					}
				}
			},
			isSuperAdmin: false,
			completeGridName: null,
			selectedRow: null,
			externalFilter: null
		};
	},
	computed: {
		rules() {
			return this.$store.getters.getRules;
		}
	},
	created() {
		this.completeGridName = this.modelName + '_' + this.$store.getters.getGridNumberOfInstances(this.modelName);
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
		this.selectedRow = document.getElementsByClassName('selected');
		let rows = document.getElementsByTagName('tr');
		console.log('debug row', this.selectedRow, this.completeGridName, rows);

		setRules(this.$store);
		setTimeunits(this.$store);
		this.setFormFilters();
		this.$store.commit('setFilterData', { groupOp: 'and', groups: [], rules: [] });

		this.$puiEvents.$on(`pui-modalDialog-deleteRegistry-${this.modelName}-ok`, () => {
			let selectedItems = document.getElementsByClassName('selected');
			let selectedRule = this.rules.find((rule) => rule.pmrulesid == selectedItems[0].id);
			if (selectedItems.length > 0 && selectedRule) {
				//alert('captured event id: ' + selectedItems[0].id + ', ' + selectedRule.ruleid);
				console.warn('deleting rule', selectedRule.ruleid);
				deleteRule({ id: selectedRule.ruleid })
					.then((solved) => {
						this.$puiNotify.info(this.$t('common.status') + ': ' + solved.status, this.$t('common.ruleDeleted'));
						console.info('info delete: solved rule', solved);
					})
					.catch((error) => {
						this.$puiNotify.error(error.response.data.message, this.$t('common.ruleDeletedError'));
						console.info('info delete: error rule', error, error.response, error.response.data);
					});
			} else {
				this.$puiNotify.warning('No record selected', this.$t('common.error'));
			}
			console.log('debug delete row', selectedItems, this.selectedRow, selectedRule);

			/* this.save();
			this.$puiEvents.$emit(`onPui-apply-delete-${this.modelName}`, this.selectedRow);
			this.showDeleteRegistryDialog = false; */
		});
		this.storeParameters();
	},
	updated() {
		console.log('debug updated rules form', document.getElementsByClassName('selected'));
	},
	beforeDestroy() {
		console.log('debug beforedestroy rules form', document.getElementsByClassName('selected'), this.completeGridName + '_body');
		this.$puiEvents.$off(`pui-modalDialog-deleteRegistry-${this.modelName}-ok`);
	},
	methods: {
		async storeParameters() {
			let filter = {};
			if (!this.isSuperAdmin) {
				filter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				};
			}
			let body = {
				model: 'vluppmparameterdatasourcetype',
				rows: 999,
				filter: filter
			};

			let { data } = await search(body);
			//console.debug('After get data - All Parameters', data.data, body);
			this.$store.commit('setAllParameters', data.data);
		},
		async setFormFilters() {
			let filter = {
				groups: [],
				groupOp: 'and',
				rules: []
			};

			let body = {
				model: 'pmfiltercondition',
				rows: 999,
				filter: filter
			};

			let { data } = await search(body);
			//console.log('After get data - Filter', data.data, body);
			this.$store.commit('setFormFilters', data.data);
		}
	}
};
</script>

<style lang="postcss" scoped></style>
